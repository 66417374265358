body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.border-area {
  border-width: thin;
  border-style: ridge;
  border-color: #2980b9;
}
.hr-border-area {
  border-width: thin;
  border-style: ridge;
  border-color:#1abc9c;
}
.two-border-area {
  border-width: thin;
  border-style: ridge;
  border-color:#e67e22;
}
.main {
  padding: 0 30px;
  border: 1px solid darkcyan;
  overflow: hidden;
  height: 80vh;
  overflow: scroll;
  position: relative;
}
.selectable-selectbox {
  z-index: 9000;
  position: absolute;
  cursor: default;
  background: none;
  border: 1px dashed grey;
}
.albums {
  display: inline;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* display: inline; */
}
.album-label {
  display: block;
  margin: 5px 0;
  font-size: smaller;
}
.item {
  position: relative;
  width: 135px;
  min-height: 125px;
  margin: 2px;
  float: left;
  text-align: center;
  border: 1px solid #487eb0;
  padding: 10px;
  overflow: hidden;
}
.item.not-selectable {
  background: silver;
}
.item .tick {
  /* position: absolute; */
  top: 4px;
  right: 4px;
  font-size: 10px;
  color: #009688;
  font-weight: bold;
  cursor: pointer;
}
.item h2 {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: inherit;
  font-weight: 600;
}
.item.selected {
  background: #192a56;
  color: white;
}
.item.selecting {
  border: 1px solid #192a56;
}
/* .not-selectable {} */
.counter {
  color: #009688;
}
.selectable-button {
  display: inline-block;
  margin: 0 3px 10px 3px;
}
.in-selection-mode .item {
  cursor: pointer;
}
button {
  display: inline-block;
  position: relative;
  /* width: 121px; */
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  font-size: 0.9em;
  color: #fff;
  border: none;
  outline: none;
  padding: 0;
}
.li-top-space{
  margin-top:5px 
}
.solid {border-style:solid ;color: #ecf0f1}
.borderBlack {border-style:ridge;border-width: 0.5 ;color: #232829;border-radius:5mm}
nav{
  height: auto;
  padding: 0.4em;
  }
  #nav1{
    background-color: white;
  }
  .a_nav {
    display: block;
    color: black;
    text-align: center;
    padding: 9px 10px;
    text-decoration: none;
}
.ul_nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.li_nav {
  float: left;
}
/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: .9em; /* opinion 3 */
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  
  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, -.5em);
          transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, .5em);
          transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  -webkit-transform: translate(-.5em, -50%);
          transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  -webkit-transform: translate(-.5em, -50%);
          transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  -webkit-transform: translate(.5em, -50%);
          transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  -webkit-transform: translate(.5em, -50%);
          transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@-webkit-keyframes tooltips-vert {
  to {
    opacity: .9;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}
@keyframes tooltips-vert {
  to {
    opacity: .9;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

@-webkit-keyframes tooltips-horz {
  to {
    opacity: .9;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  -webkit-animation: tooltips-vert 300ms ease-out forwards;
          animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  -webkit-animation: tooltips-horz 300ms ease-out forwards;
          animation: tooltips-horz 300ms ease-out forwards;
}










/* UNRELATED to tooltips */
body {
  margin: 0;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* font-family: sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* background: #ededed; */
}
main {
  -webkit-flex: 1 1 100vh;
          flex: 1 1 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
aside {
  -webkit-flex: none;
          flex: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #49b293;
  color: #fff;
  padding: 1em;
}
main div {
  text-align: center;
  color: #353539;
}
main span {
  padding: .5em 1em;
  margin: .5em;
  display: inline-block;
  background: #dedede;
}

aside a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  padding: .4em 1em;
}
.body {
background-color: white
}

input[type="checkbox"] {
  /* Double-sized Checkboxes */ /* IE */ /* FF */
  -webkit-transform: scale(1.7); /* Safari and Chrome */ /* Opera */
  transform: scale(1.7);
  /* padding: 10px; */
  
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: lightgray;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  }
  
  input[type="checkbox"]:checked {
  background: #2aa1c0;
  }
  
  input[type="checkbox"]:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
  }
  
  input[type="checkbox"]:after {
  content: '';
  position: relative;
  left: 41%;
  top: 20%;
  width: 35%;
  height: 50%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: none;
  }
  
  input[type="checkbox"]:checked:after {
  display: block;
  }
  .duration-dislay{
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
  }

  html, body{
    margin:0;
    padding:0;
    height:100%;
  }
  section {
    position: relative;
    border: 1px solid #000;
    padding-top: 37px;
    background: #500;
  }
  section.positioned {
    position: absolute;
    top:100px;
    left:100px;
    width:800px;
    box-shadow: 0 0 15px #333;
  }
  .table-fixed thead {
    width: 97%;
  }
  .table-fixed tbody {
    height: 230px;
    overflow-y: auto;
    width: 100%;
  }
  .table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
    display: block;
  }
  .table-fixed tbody td, .table-fixed thead > tr> th {
    float: left;
    border-bottom-width: 0;
    color: #ccc;
  }
  .vl {
    border-left: 3px solid #bdc3c7;
    height: 20px;
  }
  .container1 {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container1:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container1 input:checked ~ .checkmark {
    background-color: rgb(60, 162, 102);
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container1 input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container1 .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }


  

  input[type=time] {
    border: none;
    color: #2a2c2d;
    font-size: 15 px;
    font-family: helvetica;
    width: 130px;
  }
  
  /* Wrapper around the hour, minute, second, and am/pm fields as well as 
  the up and down buttons and the 'X' button */
  input[type=time]::-webkit-datetime-edit-fields-wrapper {
    display: -webkit-flex;
    display: flex;
  }
  
  /* The space between the fields - between hour and minute, the minute and 
  second, second and am/pm */
  input[type=time]::-webkit-datetime-edit-text {
    padding: 7px 0px;
  }
  
  /* The naming convention for the hour, minute, second, and am/pm field is
  `-webkit-datetime-edit-{field}-field` */
  
  /* Hour */
  input[type=time]::-webkit-datetime-edit-hour-field {
    background-color: #f2f4f5;
    border-radius: 10%;
    padding: 7px 7px;
  }
  
  /* Minute */
  input[type=time]::-webkit-datetime-edit-minute-field {
    background-color: #f2f4f5;
    border-radius: 10%;
    padding: 7px 7px;
  }
  
  /* AM/PM */
  input[type=time]::-webkit-datetime-edit-ampm-field {
    background-color: #6ab04c;
    border-radius: 10%;
    color: #fff;
    padding: 7px 7px;
  }
  
  /* 'X' button for resetting/clearing time */
  input[type=time]::-webkit-clear-button {
    display: none;
  }
  
  /* Up/Down arrows for incrementing/decrementing the value */
  input[type=time]::-webkit-inner-spin-button {
    display: none;
  }
 
  
  
  
  input[type=time] {
    border: none;
    color: #2a2c2d;
    font-size: 14px;
    font-family: helvetica;
    width: 160px;
  }
  
  /* Wrapper around the hour, minute, second, and am/pm fields as well as 
  the up and down buttons and the 'X' button */
  input[type=time]::-webkit-datetime-edit-fields-wrapper {
    display: -webkit-flex;
    display: flex;
  }
  
  /* The space between the fields - between hour and minute, the minute and 
  second, second and am/pm */
  input[type=time]::-webkit-datetime-edit-text {
    padding: 10px 4px;
  }
  
  /* The naming convention for the hour, minute, second, and am/pm field is
  `-webkit-datetime-edit-{field}-field` */
  
  /* Hour */
  input[type=time]::-webkit-datetime-edit-hour-field {
    background-color: #f2f4f5;
    border-radius: 15%;
    padding: 10px 8px;
  }
  
  /* Minute */
  input[type=time]::-webkit-datetime-edit-minute-field {
    background-color: #f2f4f5;
    border-radius: 15%;
    padding: 10px 8px;
  }
  
  /* AM/PM */
  input[type=time]::-webkit-datetime-edit-ampm-field {
    background-color: #27ae60;
    border-radius: 15%;
    color: #fff;
    padding: 10px 8px;
  }
  
  /* 'X' button for resetting/clearing time */
  input[type=time]::-webkit-clear-button {
    display: none;
  }
  
  /* Up/Down arrows for incrementing/decrementing the value */
  input[type=time]::-webkit-inner-spin-button {
    display: none;
  }
  

  /* table design */
.table-time-sheet .tr th{
  color: white;
}

.row_parent {
  width: 100%;
  margin: 0 auto;
}
.alignHOrizontal {
  width: 100px;
  float: left;
}

input[type="radio"] {
  /* Double-sized radioes */ /* IE */ /* FF */
  -webkit-transform: scale(1.7); /* Safari and Chrome */ /* Opera */
  transform: scale(1.7);
  /* padding: 10px; */
  
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: lightgray;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  border-radius: 10px
  }
  
  input[type="radio"]:checked {
  background: #2aa1c0;
  }
  
  input[type="radio"]:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
  }
  
  input[type="radio"]:after {
  content: '';
  position: relative;
  left: 41%;
  top: 20%;
  width: 35%;
  height: 50%;
  border: solid #fff;
 
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: none;
  }
  
  input[type="radio"]:checked:after {
  display: block;
  }

  .dashed {
    border-style: dashed;
    border-width: 3px;
  }

  .add {
    padding-left: 7px;
    padding-right: 7px;
  }

  .borderinput {
    border: 2px solid red;
  }
  .main11 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:row;
            flex-direction:row;
  }
  .main12 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:row-reverse;
            flex-direction:row-reverse;;
  }
  thead.fixed {
    position: fixed;
    z-index: 99;
    top: 0;
  }
  .flex-d {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    /* margin-left: 10px */
  }
  
  /* .section {
    float: right;
    width: 100%;
  } */
  .section_div {
    width: 100%;
    /* margin: 50px auto; */
    background-color:"#fff";
    overflow: hidden;
    padding: 20px;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0,0,0,.3);
    z-index: 3;
    position: relative;
  }
  .section_div_img {
    float: left;
    width: 60px;
    height: 60px;
  }
  .section_div_textarea {
    width: 600px;
    height: 100px;
    border: none;
    padding: 5px 10px;
    font-family: arial;
    resize: none;
    outline: none;
    /* border-bottom: 2px solid #0077CC; */
  }
  .textarea::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }
  .textarea::-webkit-scrollbar-thumb {
    background-color: #CCC;
    border-radius: 2px
  }
  section div [type = submit] {
    float: right;
    margin: 15px 10px auto auto;
    border: none;
    background-color: #0077CC;
    color: #fff;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 3px;
    font-size: 16px;
  }
  .overlay {
    background-color: rgba(0,0,0,.5);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    display: none;
  }
  .post {
    background-color: #fff;
    width: 500px;
    margin: 10px auto;
    padding: 10px;
    color: #333;
    box-shadow: 1px 1px 1px rgba(0,0,0,.3);
    border-radius: 3px;
  }
  .panelHeading{
   border-style: solid;
   border-width: thin;

    border-color: #bdc3c7
  }
  .input::-webkit-input-placeholder {
    color: rgb(139, 131, 131);
    font-size: 17px
  }
  .input::-ms-input-placeholder {
    color: rgb(139, 131, 131);
    font-size: 17px
  }
  .input::placeholder {
    color: rgb(139, 131, 131);
    font-size: 17px
  }
  .HR{
    border-bottom-style: solid;
    border-width: thin;
 
     border-color: #bdc3c7
   }
   .text-color{
      color:#0f9e3e

   }
   .text-justify-item{
     text-align: justify;
     font-size: 11.8px
   }
   pre {
    background: #fff;
    display: inline-block;
    font-size: .55em;
    margin-top: 2em;
    padding: 1em;
 }
 
 @media (min-width: 360px) {
    pre {
       font-size: .7em;
    }
 }
 
 @media (min-width: 500px) {
    pre {
       font-size: 1em;
    }
 }
 
 
 /*== start of code for tooltips ==*/
 .tool {
     cursor: help;
     position: relative;
 }
 
 
 /*== common styles for both parts of tool tip ==*/
 .tool::before,
 .tool::after {
     left: 50%;
     opacity: 0;
     position: absolute;
     z-index: -100;
 }
 
 .tool:hover::before,
 .tool:focus::before,
 .tool:hover::after,
 .tool:focus::after {
     opacity: 1;
     -webkit-transform: scale(1) translateY(0);
             transform: scale(1) translateY(0);
     z-index: 100; 
 }
 
 
 /*== pointer tip ==*/
 .tool::before {
     border-style: solid;
     border-width: 1em 0.75em 0 0.75em;
     border-color: #3E474F transparent transparent transparent;
     bottom: 100%;
     content: "";
     margin-left: -0.5em;
     margin-right: -0.5em;
     transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
     -webkit-transform:  scale(.6) translateY(-90%);
             transform:  scale(.6) translateY(-90%);
 } 
 
 .tool:hover::before,
 .tool:focus::before {
     transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
 }
 
 
 /*== speech bubble ==*/
 .tool::after {
     background: #3E474F;
     border-radius: .25em;
     bottom: 180%;
     color: #EDEFF0;
     content: attr(data-tip);
     margin-left: -8.75em;
     padding: 1em;
     transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
     -webkit-transform:  scale(.6) translateY(50%);
             transform:  scale(.6) translateY(50%);  
     width: 17.5em;
 }
 
 .tool:hover::after,
 .tool:focus::after  {
     transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
 }
 
 @media (max-width: 760px) {
   .tool::after { 
         font-size: .75em;
         margin-left: -5em;
         width: 10em; 
   }
 }
 .input {
  /* Tell the input to use all the available space */
  -webkit-flex-grow:2;
          flex-grow:2;
  /* And hide the input's outline, so the form looks like the outline */
  border:none;
  border-radius:20px;

}

.input:focus {
  /* removing the input focus blue box. Put this on the form if you like. */
  outline: none;
}
.backgroundC {
  background-color: rgba(black, 0.5)!important;
  /* filter: alpha(opacity=50); */
}
.form {
  /* This bit sets up the horizontal layout */
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:row;
          flex-direction:row;
  
  /* This bit draws the box around it */
  border:1px solid grey;
  border-color: #bdc3c7;
  /* I've used padding so you can see the edges of the elements. */
  padding:2px;
  border-radius:20px;
 

  
}
table {
  border-collapse: collapse;
}

td {
  padding: 5px;
}

.button {
  opacity: 0;
}

tr:hover .button {
  opacity: 1;
}
tr:hover .button1 {
  opacity: 0;
}
tr:hover {
 background-color: #eee;
}
.aclass{
  padding: 10px;
  text-decoration: none;
  color: black;
}
.aclass:hover{
  background-color: rgba(0,0,0,.2);
  color: black;
  padding: 10px;
  border-radius: 12px;
}
/* .button {
  opacity: 0;
}

tr:hover .button {
  opacity: 1;
} */
.mapsalign{
  display: -webkit-flex;
  display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    
}
.aligning{
  display: -webkit-flex; /* Safari */
  -webkit-flex-direction: row-reverse; /* Safari 6.1+ */
  display: flex;
  flex-direction: row-reverse;
}

.aligning-2{
  display: -webkit-flex; /* Safari */
  -webkit-flex-direction: row; /* Safari 6.1+ */
  display: flex;
  flex-direction: row;
}

.tooltipparent {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted #2f3640; */
}

.tooltipparent .tooltiptextbody {
  visibility: hidden;
  width: 140px;
  background-color: #2f3640;
  color: #fff;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 0;
  margin-left: -60px;
  bottom: 100%;
}

.tooltipparent:hover .tooltiptextbody {
  visibility: visible;
}  

.map_zindex{
  z-index: 1010;
}
.mapnavigation{
  z-index: 1011;
}
.navbar1{
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
} 

@-webkit-keyframes bounce {
  0%, 20%, 40%, 60%, 80%, 100% {-webkit-transform: translateY(0);}
  50% {-webkit-transform: translateY(-5px);}
} 

@keyframes bounce { 
  0%, 20%, 40%, 60%, 80%, 100% {-webkit-transform: translateY(0);transform: translateY(0);}
  50% {-webkit-transform: translateY(-5px);transform: translateY(-5px);}
} 

.bounce { 
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.mapcard{
  width: 22%;
  height: 310px;
  background-color: rgba(28, 37, 45, 0.5);
  position: absolute;
  padding: 2px;
  margin-top: 5%;
}

.mapcard h3{
  color: white;
  z-index: 1020;
  margin-top: 7%;

}
.hovertd{
  color: white;
}

.hovertd:hover{
  color: black;
}
  
.activenav{
  background-color: #404040;
}
.activenav a{
  color: white;
}

.inactivenav{
  background-color: #ededed;
}

.inactivenav a{
  color: black;
}


.white{
  color: white;
}

.white:hover{
  color: black;
}
/* style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}; */


.nameflex{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 5%;
}

@media (max-width: 600px){
  .nameflex{
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.dotted_style {
  border-style: dashed;
  border-left:#fff ;
  border-top:#fff ;
  border-bottom:#fff ;
}
 .border_solid {
   border-style: solid;
 }
 .border_solid_bottom{
 border-bottom-width: 0.3ch;
 border-top-width: 0.08ch;
  border-style: solid;
  border-left:#fff ;
  border-right:#fff ;
}
.border_solid_bottom_blank{
  border-bottom-width: 0.3ch;
   border-style: solid;
   border-left:#fff ;
   border-right:#fff ;
   border-top:#fff ;

 }

 .table_height{
overflow: hidden;
height:9px;
white-space: nowrap;
}
.remove-last-row tr:last-child td{
  border: 0;

}
.box-my-shadow{
  box-shadow: 0px 3px 6px 2px rgba(209,207,209,1);
}

.tableFixHead    { overflow-y: auto; height: 400px; }
.tableFixHead th { position: -webkit-sticky; position: sticky; top: 0; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }
::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }


  .img-magnifier-container {
    position:relative;
  }
  
  .img-magnifier-glass {
    position: absolute;
    border: 1px solid #000;
    border-radius: 10%;
    cursor: none;
    /*Set the size of the magnifier glass:*/
    width: 150px;
    height: 110px;
  }



  .profile-bar{
    display: block;
    font-weight: 600;
    padding-left: 0px;
    padding-right: 20px;
  }


  .profile-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.profile-nav>li>a {
  padding: 8px 15px;
}

.profile-highlight {
  padding: 12px 15px;
  background: #FEFDE1;
  border-radius: 4px;
}






.accordions {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.active, .accordions:hover {
  background-color: #ccc;
}

.accordions:after {
  content: '+';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.panelacc {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}


label input {
  display: none;
}



.accordionHeader {
  margin: 0 auto;
  border: 1px black solid;
  border-radius: 1px;
  background-color: white;
  overflow: auto;
  
}
.accordionInfo {
  height: 0;
  overflow: auto;
  transition: .4s;
  background-color: white;
  
}

label input:checked ~ .accordionInfo{
  height: 150px;
}

.iconFloat {
  float: right;
  margin: 15px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.iconFloat2 {
  float: right;
  margin: 15px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.pointer{
  cursor: pointer;
}

.div-left-right{
  border-left: 1px solid  #a3a4a6;
  border-right: 1px solid  #a3a4a6;
}

.div-left{
  border-left: 1px solid  #a3a4a6;
}



