.tableFixHead    { overflow-y: auto; height: 400px; }
.tableFixHead th { position: sticky; top: 0; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }
::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }


  .img-magnifier-container {
    position:relative;
  }
  
  .img-magnifier-glass {
    position: absolute;
    border: 1px solid #000;
    border-radius: 10%;
    cursor: none;
    /*Set the size of the magnifier glass:*/
    width: 150px;
    height: 110px;
  }



  .profile-bar{
    display: block;
    font-weight: 600;
    padding-left: 0px;
    padding-right: 20px;
  }


  .profile-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.profile-nav>li>a {
  padding: 8px 15px;
}

.profile-highlight {
  padding: 12px 15px;
  background: #FEFDE1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}






.accordions {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.active, .accordions:hover {
  background-color: #ccc;
}

.accordions:after {
  content: '\002B';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.panelacc {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}


label input {
  display: none;
}



.accordionHeader {
  margin: 0 auto;
  border: 1px black solid;
  border-radius: 1px;
  background-color: white;
  overflow: auto;
  
}
.accordionInfo {
  height: 0;
  overflow: auto;
  transition: .4s;
  background-color: white;
  
}

label input:checked ~ .accordionInfo{
  height: 150px;
}

.iconFloat {
  float: right;
  margin: 15px;
  transform: rotate(180deg);
}

.iconFloat2 {
  float: right;
  margin: 15px;
  transform: rotate(0deg);
}

.pointer{
  cursor: pointer;
}

.div-left-right{
  border-left: 1px solid  #a3a4a6;
  border-right: 1px solid  #a3a4a6;
}

.div-left{
  border-left: 1px solid  #a3a4a6;
}


